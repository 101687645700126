import React, { Component } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { utils } from "../utils.js"

import Button from '../components/Button.js'
import Tag from "./Tag.js"

function renderimage(objectPosition, image, classInner, imageSrc) {
  if (objectPosition){
    return(
      <GatsbyImage image={image} className={`bg-pattern-linear-darker roundr-top card-feature-image ${classInner}`} objectPosition={objectPosition} alt={imageSrc.altText} />
    )
  }else{
    return(
      <GatsbyImage image={image} className={`bg-pattern-linear-darker roundr-top card-feature-image ${classInner}`} alt={imageSrc.altText} />
    )
  }
}

export default class BlogPost extends Component { 
  
  render() {
    const { title, excerpt, toPath, toPathTag, tag, date, targetBlank, classInner, objectPosition } = this.props
    let { imageSrc } = this.props

    if(!imageSrc) imageSrc = { localFile: "../../images/post-image.png", altText: "Placeholder Image" }

    let image = null
    let imageSearch = false
    if(typeof imageSrc.localFile === "string" && imageSrc.localFile.includes('http')) {
      imageSearch = true
    } else {
      image = getImage(imageSrc.localFile)
    }
    
    const parsedDate = utils.toBlogDate(date)
    return (
          <div className="bg-color-golden-20 p-2r rounded">     
            <Button to={toPath} className="dfc overlapped" targetBlank={targetBlank}>
              {image &&
                renderimage(objectPosition, image, classInner, imageSrc)
              }              
              { imageSearch &&
                <div className="gatsby-image-wrapper gatsby-image-wrapper-constrained roundr-top card-feature-image blog-index">
                  <img src={imageSrc.localFile} alt={imageSrc.altText} />
                </div>
              }
            </Button>
            <Tag to={toPathTag} text={tag} />
            <Button to={toPath} className="dfc" targetBlank={targetBlank}>
              <p className="c-purple-2 pb-05r">{parsedDate}</p>
              <h4 className="h3 mb-1r c-purple">
                {title}
              </h4>
              <p className="c-purple-1" dangerouslySetInnerHTML={{ __html: excerpt }} />
            </Button>
          </div>
        
    ) 
  }
}
