import React, { Component } from "react"
import Container from "../helpers/Container.js"
import { utils } from "../utils.js";

class MaterialFilterableItems extends Component {

  constructor(props) {
    super(props)
    this.state = { 
      filteredItems: [],
      active: 'all'
    }

    this.filterItems = this.filterItems.bind(this);
  }

  componentDidMount() {
    this.setState({ filteredItems: this.props.items });
  }

  filterItems(filter) {
    const { items } = this.props;
    let newItems = items;

    if(filter !== 'all') {
      newItems = items.filter((el) => {
        return utils.getFields(el.categoriasMateriaisEducativos.nodes, 'slug').includes(filter)
      });      
    }

    this.setState({ filteredItems: newItems, active: filter });
  }

  render() {

    const { filters, Item, ItemContainer, filtersKey } = this.props;
    const { filteredItems, active } = this.state;

    return (
      <Container className="container-xl dfc">
        <p className="c-grey-2 tac mt-4r mb-15r">Navegue por serviços:</p> 
        <div className="df fw-wrap jc-c">
          <div role="button"
            tabIndex="0"
            className={`tag bg-color-golden c-purple round mr-15r mb-15r c-p ${active === 'all' ? 'active-tag' : ''}`}
            onClick={() => this.filterItems('all')}
            onKeyDown={() => this.filterItems('all')}
          > 
            Todos
          </div>
          {
            filters.map((el, key) => (
              <div role="button"
                tabIndex="0"
                className={`tag bg-color-golden c-purple round mr-15r mb-15r c-p ${active === el[filtersKey] ? 'active-tag' : ''}`}
                onClick={() => this.filterItems(el[filtersKey])}
                onKeyDown={() => this.filterItems(el[filtersKey])}
                key={key}
              > 
                {el.name}
              </div>
            ))
          }
        </div>
        <ItemContainer className="container-fluid pb-4r">
          <div className="row justify-content-center">
          {
          filteredItems.map((el, key) => (
            <div className="col-12 col-sm-10 col-md-8 col-lg-4 d-flex mb-5" key={key}>
              <Item 
                targetBlank
                toPath={el.materiaisEducativos.comunicacaoMaterialEducativoUrl}
                key={key}
                toPathTag={null}
                imageSrc={(el.featuredImage) ? el.featuredImage.node : null}
                title={el.title}
                date={utils.toBlogDate(el.date)}
                excerpt={""}
                tag={el.categoriasMateriaisEducativos.nodes[0].name}
                classInner="blog-index"
                objectPosition="0 50%"
              />
            </div>
            ))
          }

          </div>
          
        </ItemContainer>
      </Container>
    )
  }
}


export default MaterialFilterableItems;