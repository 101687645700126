import React from "react"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Page from "../common/elements/Page.js"
import BlogPost from "../common/elements/BlogPost.js"

import ContainerWithBg from "../common/helpers/ContainerWithBg.js"
import Container from "../common/helpers/Container.js"

import MaterialFilterableItems from "../common/components/MaterialFilterableItems.js"

function MateriaisEducativos() {

  const data = useStaticQuery(graphql`
  {
    allWpCategoriaMaterialEducativo {
      nodes {
        id
        name
        slug
      }
    }
    allWpMaterialEducativo {
      nodes {
        id
        slug
        title
        date(formatString: "D M y")
        categoriasMateriaisEducativos {
          nodes {
            name
            slug
          }
        }
        materiaisEducativos {
          comunicacaoMaterialEducativoUrl
          fieldGroupName
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    wpPage(databaseId: {eq: 19045}) {
      nodeType
      title
      uri
      seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          canonical
          cornerstone
          schema {
              articleType
              pageType
              raw
          }
      }
    }
  }
  `)

  return (
    <Page>
      <Seo post={data.wpPage} />
      <Helmet>
        <link rel="preload" as="image" href="../images/materiais-educativos-2.png"></link>
      </Helmet>
      <ContainerWithBg 
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-materiais-educativosb py-8 py-lg-0"
        className="container-md tac mh-27r ai-c jc-c dfc c-white feature-image"
      >
        <h1 className="z1 h1-egg mb-4">Materiais Educativos</h1>
        <p className="z1 mb-0">
          A partir da nossa expertise de mais de dez anos em Diagnóstico e Planejamento de Comunicação Integrada, Gestão de Riscos Reputacionais e Mapeamento de fluxos e processos de comunicação, desenvolvemos diversos materiais educativos para sua empresa.
        </p>
      </ContainerWithBg>
      <MaterialFilterableItems 
        filters={data.allWpCategoriaMaterialEducativo.nodes}
        items={data.allWpMaterialEducativo.nodes}
        Item={BlogPost}
        ItemContainer={Container}
        filtersKey="slug"
        searchKey="categoriasMateriaisEducativos.nodes[0].slug"
      />
    </Page>
  )
}

export default MateriaisEducativos;