const utils = {
  
  monthArray() {
    return [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ]
  },
  
  toBlogDate(date) {
    let parsedDate = date.split(" ")
    parsedDate[1] = this.monthArray()[parsedDate[1]-1]
    return parsedDate.join(" ")
  },

  toSingleBlogDate(date) {
    let parsedDate = date.split("/")
    parsedDate[1] = this.monthArray()[parsedDate[1]-1]
    return parsedDate.join("/")
  },

  getAuthorName(author) {
    return (author.nicename) ? author.nicename : `${author.firstName} ${author.lastName}`
  },

  getFields(arr, field = "databaseId") {
    return arr.map(el => {
      return el[field]
    })
  }
}

export { utils }